// https://github.com/i-rocky/country-list-js
import countryList from "country-list-js";

export interface CountryInfo {
    code: { iso2: string; iso3: string };
    name: string;
}

interface CountryListInfo {
    iso2: string;
    iso3: string;
    name: string;
}

export function getIso3CountryInfo(code: string) {
    return countryList.findByIso3(code.toUpperCase()) as CountryInfo | undefined;
}

export function getIso2CountryInfo(code: string) {
    return countryList.findByIso2(code.toUpperCase()) as CountryInfo | undefined;
}

export function allCountries() {
    return Object.values(countryList.all as Record<string, CountryListInfo>).map(
        ({ iso2, iso3, name }): CountryInfo => ({ code: { iso2, iso3 }, name }),
    );
}
