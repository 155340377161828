import type { Location } from "@warrenio/api-spec/spec.oats.gen";
import { Suspense } from "react";
import { DetailsTableRow } from "../../components/table/DetailsTableRow.tsx";
import { CountryFlag } from "./CountryFlag.tsx";
import { getIso3CountryInfo } from "./locationCountry.ts";
import { useSingleLocationSuspense } from "./query.ts";

export function LocationDetail({ slug }: { slug: string }) {
    return (
        <DetailsTableRow title="Location:" tooltip="Data center where resource is located">
            <Suspense>
                <LocationText slug={slug} />
            </Suspense>
        </DetailsTableRow>
    );
}

export function LocationText({ slug }: { slug: string }) {
    const location = useSingleLocationSuspense(slug);

    const { country_code } = location;
    const country3code = country_code && getIso3CountryInfo(country_code)?.code.iso3;

    return (
        <span className="flex gap-0.5rem">
            <CountryFlag code={country3code} />
            <span>
                <LocationName location={location} />
            </span>
        </span>
    );
}
export function LocationName({
    location: { display_name, slug, country_code },
    showCountry = false,
}: {
    location: Location;
    showCountry?: boolean;
}) {
    let countryLabel;
    if (showCountry) {
        const name = getIso3CountryInfo(country_code)?.name;
        if (name != null && name !== display_name) {
            countryLabel = name;
        }
    }

    return (
        <>
            {display_name} ({slug}){countryLabel && <>, {countryLabel}</>}
        </>
    );
}
