import C from "./CountryFlag.module.css";

import { basepath } from "../main/urls.ts";
import { getIso3CountryInfo } from "./locationCountry.ts";

function getFlagUrl(iso2: string) {
    return `${basepath}/flags/${iso2.toLowerCase()}.svg`;
}

export function CountryFlag({ code, className = C.countryFlag }: { code?: string; className?: string }) {
    const country = code && getIso3CountryInfo(code);
    if (country) {
        return <img src={getFlagUrl(country.code.iso2)} alt={country.name} className={className} />;
    }

    return <CountryFlagPadding className={className} />;
}

export function CountryFlagPadding({ className = C.countryFlagPadding }: { className?: string }) {
    return <div className={className} />;
}
